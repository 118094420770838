<template>
  <div v-if="qrCodeData.length > 0">
    <button class="m-0 button is-primary is-small is-outlined" :class="{ 'is-loading': loading }" @click="downloadQRCodes">
      <i class="fas fa-qrcode mr-2"></i>
      Download Tasks & Items QR Codes
    </button>
    <div v-for="(qrCode, index) in qrCodeData" :key="index">
      <vue-qrcode v-show="false" :id="`qr-code-${index}`" :value="qrCode.value" />
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'DownloadGameQRCodesButton',
  components: {
    VueQrcode
  },
  props: {
    adventureDetails: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    qrCodeData () {
      const qrCodes = []
      if (this.adventureDetails.stages) {
        this.adventureDetails.stages.forEach(stage => {
          stage.challenges.forEach(challenge => {
            if (challenge.type === 'qrbarcode') {
              challenge.answers.forEach((answer, index) => {
                const sanitizedChallenge = challenge.name.replace(/[^a-zA-Z0-9 ]/g, '')
                const sanitizedAnswer = answer.value.replace(/[^a-zA-Z0-9 ]/g, '')
                qrCodes.push({ value: answer.value, name: `${sanitizedChallenge} - ${index + 1} - ${sanitizedAnswer}` })
              })
            }
            if (challenge.access_code) {
              const sanitizedChallenge = challenge.name.replace(/[^a-zA-Z0-9 ]/g, '')
              const sanitizedAccessCode = challenge.access_code.replace(/[^a-zA-Z0-9 ]/g, '')
              qrCodes.push({ value: sanitizedAccessCode, name: `${sanitizedChallenge} - Task Access Code - ${sanitizedAccessCode}` })
            }
          })
        })
      }
      if (this.adventureDetails.searchableItems) {
        this.adventureDetails.searchableItems.forEach(item => {
          if (item.scanCode) {
            const sanitizedItemName = item.item.substring(0, 10).replace(/[^a-zA-Z0-9 ]/g, '')
            const sanitizedScanCode = item.scanCode.replace(/[^a-zA-Z0-9 ]/g, '')
            qrCodes.push({ value: sanitizedScanCode, name: `${sanitizedItemName} - Item Scan Code - ${sanitizedScanCode}` })
          }
        })
      }
      return qrCodes
    }
  },
  methods: {
    downloadQRCodes () {
      this.loading = true

      this.qrCodeData.forEach((qrCode, index) => {
        const qrCodeElement = document.getElementById(`qr-code-${index}`)
        console.log('qrCodeElement', qrCodeElement)
        var downloadLink = document.createElement('a')
        downloadLink.href = qrCodeElement.src
        downloadLink.download = qrCode.name
        downloadLink.click()
      })

      this.loading = false
    }
  }
}
</script>

<style scoped>
.button {
  margin: 10px
}
</style>
