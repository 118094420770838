<template>
  <div @click="promptAndTriggerBookeoEmail" class="button is-primary is-light is-small is-clickable">
    <i class="fas fa-envelope mr-2"></i>
    Trigger email
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'TriggerBookeoEmailButton',
  props: {
    teamCode: {
      type: String,
      default: null
    }
  },
  methods: {
    promptAndTriggerBookeoEmail () {
      if (!confirm('Do you want to re-trigger an email?')) {
        return
      }
      const email = prompt('Type in the email address to send the email to. Leave blank to send to the original email registered in Bookeo.')
      if (email && !this.isValidEmail(email)) {
        alert('Invalid email address. Please try again.')
        return
      }
      this.triggerBookeoEmail(email)
    },
    isValidEmail (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    triggerBookeoEmail (email) {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunctionAdmin')
      masterFunctionAdmin({
        methodName: 'trigger-bookeo-email',
        teamCode: this.teamCode,
        sendEmailInsteadTo: email || undefined
      }).then(result => {
        alert('Email triggered with specified email subject and template as configured in this session.')
      }).catch(error => {
        console.error('Error triggering email', error)
      })
    }
  }
}
</script>
