<template>
  <div class="mb-6">
    <div class="box" style="border: #3e2d6d solid !important;">
      <p class="title is-4">For Organizers</p>
      <p class="subtitle is-6">
        Access the facilitator's dashboard by clicking on the link below.
        Only share this secret link with game facilitators.
      </p>
      <div class="tags">
        <span v-for="tag in tags" :key="tag" class="tag is-dark">{{ tag }}</span>
      </div>
      <p><u @click="copyAndNavigate(faciLink)" class="is-clickable">{{ faciLink }}</u></p>
    </div>
    <div class="notification is-primary">
      <h1 class="title is-4">For Players</h1>
      <p class="subtitle is-6">Choose one of the 3 methods.</p>
      <div class="content">
        <p><strong>Method 1: Use the Game Code</strong></p>
        <ol>
          <li>
            <p>Tell players to open their browser and go to: <strong><a href="https://pyts.link" target="_blank">https://pyts.link</a></strong></p>
          </li>
          <li>
            <p>Enter the game code: <span @click="copyLink(session.id)" class="tag has-text-weight-bold is-medium is-white is-clickable is-rounded ml-1">{{ session.id }}</span></p>
          </li>
          <li>
            <p>Players will be able to play alone or create teams, as many times as possible.</p>
          </li>
        </ol>
        <p><a href="https://www.youtube.com/watch?v=U81urai5iMU" target="_blank" class="button is-small is-light is-rounded">Watch video tutorial</a></p>
      </div>
      <div class="content">
        <p><strong>Method 2: Scan a QR code</strong></p>
        <ol>
          <li>
            <p>1 player from a team to scan the QR code below.</p>
          </li>
          <li>
            <p>Create a team from the opened page.</p>
          </li>
          <li>
            <p>Share unique QR code found on the lobby page with other team members.</p>
          </li>
        </ol>
        <vue-qrcode :value="teamLink" class="qr-code" />
      </div>
      <div class="content">
        <p><strong>Method 3: Use Team Codes</strong></p>
        <ol>
          <li>
            <p>Click on the "Teams" tab above.</p>
          </li>
          <li>
            <p>Type the team name and click "Add Pre-created Team".</p>
          </li>
          <li>
            <p>Copy the team code instructions and share it with the team. This prevents them from creating more teams.</p>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'

export default {
  name: 'SessionTutorial',
  components: {
    VueQrcode
  },
  data () {
    return {
      tags: [
        'Live Leaderboard',
        'Message Players',
        'Answer Player Queries',
        'Make Judgements for Photos & Videos',
        'Add/Remove Points',
        'View Submitted Photos & Videos',
        'See Teams\' Locations'
      ]
    }
  },
  props: {
    session: {
      type: Object,
      default: null
    }
  },
  computed: {
    link () {
      return `${this.$store.state.baseUrl}/p/${this.session.id}`
    },
    faciLink () {
      return `https://admin.playtours.app/faci/${this.session.facilitatorKey}?upd=1`
    },
    teamLink () {
      return `https://pyts.link/p/${this.session.id}`
    }
  },
  methods: {
    newTab (url) {
      window.open(url)
    },
    async copyLink (link) {
      await navigator.clipboard.writeText(link)
      this.$buefy.toast.open({
        message: 'Game code copied! Paste (ctrl-v) it anywhere.',
        type: 'is-primary',
        queue: false
      })
    },
    async copyAndNavigate (url) {
      await navigator.clipboard.writeText(url)
      this.$buefy.toast.open({
        message: 'Link copied to clipboard! Opening page in new tab...',
        type: 'is-primary',
        queue: false
      })
      setTimeout(() => {
        this.newTab(url)
      }, 2000)
    }
  }
}
</script>
